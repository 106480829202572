<template>
    <BT-Blade-Items
        addBladeName="courier-price-tier"
        bladeName="courier-price-tiers"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :headers="[{ text: 'Tier Name', value: 'priceTierName', title: 1, searchable: true }]"
        navigation="courier-price-tiers"
        title="Courier Price Tiers" />
</template>

<script>
export default {
    name: 'Courier-Price-Tiers-Blade',
    props: {
        bladesData: null
    }
}
</script>